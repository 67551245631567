<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-26 17:02:00
 * @LastEditTime: 2021-05-27 14:49:22
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="news-card" @click="goArticle">
    <div class="time">
      <div class="month">{{ month }}月</div>
      <div class="day">{{ day }}</div>
    </div>
    <span class="title">{{ info.name ? info.name : info.title }}</span>
  </div>
</template>

<script>
export default {
  props: ['source', 'info'],
  data () {
    return {
      day: '',
      month: ''
    }
  },
  created () {
    let arr = this.info.create_time.split('-')
    this.month = arr[1]
    this.day = arr[2]
  },
  methods: {
    goArticle () {
      if (this.source == 'News') {
        this.$router.push({ path: '/news/article', query: { id: this.info.id } })
      } else if (this.source == 'Notice') {
        this.$router.push({ path: '/investorRelations/article', query: { id: this.info.id } })
      }
    }
  },
}
</script>

<style scoped lang='scss'>
.news-card {
  padding: 20px 0;
  border-top: 1px solid #bfbfbf;
  cursor: pointer;
  &:last-child {
    border-bottom: 1px solid #bfbfbf;
  }
  .time {
    display: inline-block;
    width: 34px;
    height: 34px;
    color: #fff;
    background: #b7a16f;
    text-align: center;
    vertical-align: middle;
    margin-right: 20px;
    .month {
      font-size: 10px;
      margin-top: 2px;
    }
    .day {
      font-size: 13px;
      font-weight: 600;
      margin-top: -4px;
    }
  }
  .title {
    &:hover {
      color: #b7a16f;
    }
  }
}
</style>