/*
 * @Description:api接口
 * @Author: Chp
 * @Date: 2021-04-09 15:51:45
 * @LastEditTime: 2021-05-27 15:00:56
 * @LastEditors: Chp
 * @Reference:
 */

import request from './request'
// import QS from 'qs'

/**
 * @description: 关于同汇
 */
const getIndex = () => {
  return request({
    url: '/Home/index',
    method: 'GET',
  })
}

/**
 * @description: 业务领域
 * @param  {*}
 * @return {*}
 */
const getBusiness = () => {
  return request({
    url: '/Home/business',
    method: 'GET',
  })
}

/**
 * @description: 投资者关系
 * @param  {*}
 * @return {*}
 */
const getNotice = () => {
  return request({
    url: '/Home/notice',
    method: 'GET',
  })
}

/**
 * @description: 投资者关系
 * @param  {*}
 * @return {*}
 */
const getNoticeDetail = (params) => {
  return request({
    url: '/Home/getNoticeDetails',
    method: 'GET',
    params,
  })
}

/**
 * @description: 新闻列表
 * @param  {*} type 1:业内新闻  2:企业新闻
 * @return {*}
 */
const getNewsList = (params) => {
  return request({
    url: '/News/newsList',
    method: 'POST',
    params,
  })
}

/**
 * @description:新闻详情
 * @param  {*}
 * @return {*}
 */
const getNewsDetail = (params) => {
  return request({
    url: '/News/newsDetail',
    method: 'GET',
    params,
  })
}

/**
 * @description: 人才理念
 * @param  {*}
 * @return {*}
 */
const getConcept = () => {
  return request({
    url: '/Home/concept',
    method: 'GET',
  })
}

/**
 * @description: 招聘信息
 * @param  {*}
 * @return {*}
 */
const getRecruit = () => {
  return request({
    url: '/Home/recruit',
    method: 'GET',
  })
}

/**
 * @description: 联系我们
 * @param  {*}
 * @return {*}
 */
const getContactUs = () => {
  return request({
    url: '/Home/contactUs',
    method: 'GET',
  })
}

export default {
  getIndex,
  getBusiness,
  getNotice,
  getNoticeDetail,
  getNewsList,
  getNewsDetail,
  getConcept,
  getRecruit,
  getContactUs,
}
