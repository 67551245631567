/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-03-23 13:54:34
 * @LastEditTime: 2021-05-26 22:17:40
 * @LastEditors: Chp
 * @Reference:
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    IndexData: {}, //首页数据
  },
  getters: {
    IndexData(state) {
      return state.IndexData
    },
  },
  mutations: {
    updateIndexData(state, data) {
      state.IndexData = data
    },
  },
  actions: {},
  modules: {},
})
