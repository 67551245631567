/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-03-23 13:54:34
 * @LastEditTime: 2021-05-26 21:42:27
 * @LastEditors: Chp
 * @Reference:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import './assets/styles/base.css' //基础样式
import './assets/styles/global.scss'

Vue.config.productionTip = false

//注册全局组件
import plugins from './components/common/index'
Vue.use(plugins)

import api from './api/api'
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
