/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-05-26 15:22:51
 * @LastEditTime: 2021-05-26 18:04:36
 * @LastEditors: Chp
 * @Reference:
 */
import SonTitle from './sonTitle.vue'
import NewsCard from './newsCard.vue'
import RichArticle from './richArticle.vue'

function plugins(Vue) {
  Vue.component('SonTitle', SonTitle) //子标题
  Vue.component('NewsCard', NewsCard)
  Vue.component('RichArticle', RichArticle)
}

export default plugins
