/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-04-09 15:51:33
 * @LastEditTime: 2021-06-23 18:39:07
 * @LastEditors: Chp
 * @Reference:
 */
import axios from 'axios'

// 环境的切换
let baseUrl = ''
if (process.env.NODE_ENV == 'development') {
  //   baseUrl = 'http://8.129.12.255:9001/'
  baseUrl = 'http://www.guangdongthkj.com'
} else if (process.env.NODE_ENV == 'production') {
  baseUrl = window.location.origin
}

let service = axios.create({
  baseURL: baseUrl,
  timeout: 300000, // request timeout
})

service.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (error.response.status) {
      return Promise.reject(error.response)
    }
  }
)

export default service
