<!--
 * @Description: 子标题
 * @Author: Chp
 * @Date: 2021-05-26 15:21:34
 * @LastEditTime: 2021-05-26 15:33:58
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="son-title">{{ title }}</div>
</template>

<script>
export default {
  name: 'SonTitle',
  props: ['title'],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.son-title {
  color: #2b2b2b;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-top: 14px;
}
</style>
