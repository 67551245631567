import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/aboutUs/intro',
  },
  {
    path: '/',
    component: () => import('../components/Layout/Layout.vue'),
    meta: {
      title: '整体页面布局',
    },
    children: [
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () => import('../views/aboutUs/index.vue'),
        meta: {
          title: '关于同汇',
        },
        redirect: '/aboutUs/intro', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'intro',
            component: () => import('../views/aboutUs/introduction.vue'),
            meta: {
              title: '同汇简介',
            },
          },
          {
            path: 'framework',
            component: () => import('../views/aboutUs/framework.vue'),
            meta: {
              title: '同汇架构',
            },
          },
          {
            path: 'government',
            component: () => import('../views/aboutUs/government.vue'),
            meta: {
              title: '公司治理',
            },
          },
          {
            path: 'concept',
            component: () => import('../views/aboutUs/concept.vue'),
            meta: {
              title: '核心理念',
            },
          },
        ],
      },
      {
        path: '/businessArea',
        name: 'BusinessArea',
        component: () => import('../views/businessArea/index.vue'),
        meta: {
          title: '业务领域',
        },
        redirect: '/businessArea/business', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'business',
            component: () => import('../views/businessArea/business.vue'),
            meta: {
              title: '业务',
            },
          },
        ],
      },
      {
        path: '/investorRelations',
        name: 'InvestorRelations',
        component: () => import('../views/investorRelations/index.vue'),
        meta: {
          title: '投资者关系',
        },
        redirect: '/investorRelations/notice', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'notice',
            component: () => import('../views/investorRelations/notice.vue'),
            meta: {
              title: '公司公告',
            },
          },
          {
            path: 'article',
            component: () => import('../views/investorRelations/article.vue'),
            meta: {
              title: '详情',
            },
          },
        ],
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/news/index.vue'),
        meta: {
          title: '新闻动态',
        },
        redirect: '/news/industry', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'industry',
            component: () => import('../views/news/industry.vue'),
            meta: {
              title: '行业信息',
            },
          },
          {
            path: 'company',
            component: () => import('../views/news/company.vue'),
            meta: {
              title: '企业动态',
            },
          },
          {
            path: 'article',
            component: () => import('../views/news/article.vue'),
            meta: {
              title: '详情',
            },
          },
        ],
      },
      {
        path: '/joinUs',
        name: 'JoinUs',
        component: () => import('../views/joinUs/index.vue'),
        meta: {
          title: '加入同汇',
        },
        redirect: '/joinUs/talentConcept', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'talentConcept',
            component: () => import('../views/joinUs/talentConcept.vue'),
            meta: {
              title: '人才理念',
            },
          },
          {
            path: 'recruitInfo',
            component: () => import('../views/joinUs/recruitInfo.vue'),
            meta: {
              title: '招聘信息',
            },
          },
        ],
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: () => import('../views/contactUs/index.vue'),
        meta: {
          title: '联系我们',
        },
        redirect: '/contactUs/contact', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'contact',
            component: () => import('../views/contactUs/contact.vue'),
            meta: {
              title: '联系我们',
            },
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
})

export default router
